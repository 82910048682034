import { createRouter, createWebHistory } from "vue-router";
import { Auth } from "aws-amplify";
import NProgress from "nprogress";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../views/PublicHome.vue"),
      meta: { requiresAuth: false },
    },
    {
      path: "/Authhome",
      name: "Auth Home",
      component: () => import("../views/AuthHome.vue"),
      meta: { requiresAuth: true },
    },
    // {
    //   path: "/Home",
    //   name: "AgencyHome",
    //   component: () => import("../views/AgencyHome.vue"),
    //   meta: { requiresAuth: true },
    // },
    // {
    //   path: "/employeeHome",
    //   name: "EmployeeHome",
    //   component: () => import("../views/EmployeeHome.vue"),
    //   meta: { requiresAuth: true },
    // },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/Login.vue"),
      meta: { requiresAuth: false },
    },
    // {
    //   path: "/Employeelogin",
    //   name: "EmployeeLogin",
    //   component: () => import("../views/EmployeeLogin.vue"),
    //   meta: { requiresAuth: false },
    // },
    {
      path: "/signup",
      name: "Sign Up",
      component: () => import("../views/Signup.vue"),
      meta: { requiresAuth: false },
    },
    {
      path: "/Employeesignup",
      name: "EmployeeSign Up",
      component: () => import("../views/EmployeeSignup.vue"),
      meta: { requiresAuth: false },
    },
    {
      path: "/AgencyAddNew",
      name: "AddNew",
      component: () => import("../views/AgencyAddNew.vue"),
      meta: { requiresAuth: false },
    },

    // {
    //   path: "/AgencyView",
    //   name: "AgencyView",
    //   component: () => import("../views/AgencyView.vue"),
    //   meta: { requiresAuth: false },
    // },

    {
      path: "/UserInformation",
      name: "UserInformation",
      component: () => import("../views/UserInformation.vue"),
      meta: { requiresAuth: false },
    },
    // {
    //   path: "/PbUserInformation",
    //   name: "PbUserInformation",
    //   component: () => import("../views/PbUserInformation.vue"),
    //   meta: { requiresAuth: false },
    // },

    // ###############################################################
    //Agency start
    {
      path: "/agency-login",
      name: "Agency Login",
      component: () => import("../views/agency/AgencyLogin.vue"),
      meta: { requiresAuth: false },
    },

    // {
    //   path: "/agency-home",
    //   name: "Agency Home",
    //   component: () => import("../views/agency/AgencyHome.vue"),
    //   meta: { requiresAuth: true, userType: "Agency" },
    // },
    {
      path: "/agency-home",
      name: "Agency Home",
      component: () => import("../views/agency/AgencyHome.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/add-new-maid",
      name: "AddNewMaid",
      component: () => import("../views/agency/AddNewMaid.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/agency-signup",
      name: "AgencySignUp",
      component: () => import("../views/agency/AgencySignUp.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/user-information/:userId",
      name: "UserInformation",
      component: () => import("../views/agency/AgencyUserInformation.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/agency-edit-maid/:id",
      name: "AgencyEditMaid",
      component: () => import("../views/agency/MaidEditForm.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/agency-view",
      name: "AgencyView",
      component: () => import("../views/agency/AgencyTableView.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/agency-enquiry",
      name: "AgencyEnquiry",
      component: () => import("../views/agency/ListEnquiries.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/agency-shortlist",
      name: "AgencyShortlist",
      component: () => import("../views/agency/AgencyShortlist.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/deals-singapore-maid-agency",
      name: "DealsSingapore",
      component: () => import("../views/agency/DealsSingaporeMaidAgency.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/certification",
      name: "Certification",
      component: () => import("../views/agency/Certification.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/staff-access",
      name: "StaffAccess",
      component: () => import("../views/agency/StaffAccess.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/agency-activity-log",
      name: "AgencyActivityLog",
      component: () => import("../views/agency/AgencyActivityLog.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/notification-today",
      name: "NotificationToday",
      component: () => import("../views/agency/TodayNotification.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/user-profile",
      name: "UserProfile",
      component: () => import("../views/agency/UserProfile.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/messages-today",
      name: "Messag",
      component: () => import("../views/agency/MessagesToday.vue"),
      meta: { requiresAuth: true, userType: ["Agency", "Staff"] },
    },

    {
      path: "/agency-info",
      name: "AgencyInfo",
      component: () => import("../views/agency/CompanyInfo.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/support",
      name: "Support",
      component: () => import("../views/agency/SupportAgency.vue"),
      meta: { requiresAuth: false },
    },

    // Agency End

    // Employer start
    {
      path: "/employer-login",
      name: "EmployerLogin",
      component: () => import("../views/employer/EmployerLogin.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/employer-signup",
      name: "EmployerSignup",
      component: () => import("../views/employer/EmployerSignUp.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/employer-dashboard",
      name: "EmployerDashboard",
      component: () => import("../views/employer/employerDashboard.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/browser-maids",
      name: "BrowserMaids",
      component: () => import("../views/employer/browserMaids.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/employer-user-profile",
      name: "EmployerUserProfile",
      component: () => import("../views/employer/UserProfileEmployer.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/my-enquiries",
      name: "MyEnquiries",
      component: () => import("../views/employer/MyEnquiries.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/submit-enquiry-all-agency",
      name: "SubmitEnquiry",
      component: () => import("../views/employer/SubmitEnquiry.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/employer-shortlist",
      name: "EmployerShortlist",
      component: () => import("../views/employer/EmployerShortlist.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/shortlist-maid",
      name: "ShortlistMaid",
      component: () => import("../views/employer/ShortlistMaid.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/maid-information/:userId",
      name: "MaidInformation",
      component: () => import("../views/employer/MaidInformation.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/about",
      name: "About",
      component: () => import("../views/AboutUsAll.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/agency",
      name: "Agency",
      component: () => import("../views/employer/EmpMaidAgency.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },

    {
      path: "/employer-settings",
      name: "EmployerSettings",
      component: () => import("../views/employer/EmployerSettings.vue"),
      meta: { requiresAuth: true, userType: "Employee" },
    },
    // Employer End

    // Public
    {
      path: "/public-user-information/:userId",
      name: "PublicUserInformation",
      component: () => import("../views/public/PublicUserInformation.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/view-shortlist-maid",
      name: "ShortListMaid",
      component: () => import("../views/public/ShortListPublicProfile.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/about-us",
      name: "AboutUs",
      component: () => import("../views/public/AboutUs.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/faq",
      name: "Faq",
      component: () => import("../views/public/PublicFaq.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/legal-disclaimer",
      name: "LegalDisclaimer",
      component: () => import("../views/public/LegalDisclaimer.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/agency/:companyName/:email",
      name: "MaidByAgency",
      component: () => import("../views/public/MaidByAgency.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/enquiry",
      name: "Enquiry",
      component: () => import("../views/public/PublicEnquiry.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/maid-by-country/:country",
      name: "MaidByCountry",
      component: () => import("../views/public/MaidByCountry.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/maid-agency",
      name: "MaidAgency",
      component: () => import("../views/public/MaidAgency.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/404",
      name: "NotFound",
      component: () => import("../views/NotFound.vue"),
      meta: { requiresAuth: false },
    },

    {
      path: "/contact-us",
      name: "Contact-Us",
      component: () => import("../views/ContactUs.vue"),
      meta: { requiresAuth: false },
    },
    // ########
  ],
});

// router.beforeResolve((to, from, next) => {
//   console.log(to);

//   if (to.matched.some((record) => record.path == "/")) {
//     Auth.currentAuthenticatedUser()
//       .then(() => {
//         next({
//           path: "/agency-home",
//         });
//       })
//       .catch(() => {
//         next();
//       });
//   } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//     //If Meta tag have requiresAuth is True => System automatically check the Login
//     Auth.currentAuthenticatedUser()
//       .then(() => {
//         next();
//       })
//       .catch(() => {
//         next({
//           path: "/agency-login",
//           query: { redirect: to.fullPath },
//         });
//       });
//   } else {
//     next();
//   }
// });

//Used
// router.beforeResolve((to, from, next) => {
//   console.log(to);

//   Auth.currentAuthenticatedUser()
//     .then((user) => {
//       const userType = user.attributes["custom:userType"];

//       if (to.matched.some((record) => record.meta.requiresAuth)) {
//         const routeUserType = to.meta.userType;

//         if (routeUserType && routeUserType !== userType) {
//           next({ path: "/404" });
//         } else {
//           next();
//         }
//       } else {
//         next();
//       }
//     })
//     .catch(() => {
//       if (to.matched.some((record) => record.meta.requiresAuth)) {
//         // Redirect to the appropriate login page based on userType
//         const loginPath =
//           to.meta.userType === "Agency" ? "/agency-login" : "/employer-login";
//         next({
//           path: loginPath,
//           query: { redirect: to.fullPath },
//         });
//       } else {
//         next();
//       }
//     });
// });
//used
router.beforeResolve(async (to, from, next) => {
  console.log(to);
  try {
    const user = await Auth.currentAuthenticatedUser();
    const userType = user.attributes["custom:userType"];
    const isAuthenticated = to.matched.some(
      (record) => record.meta.requiresAuth
    );

    if (isAuthenticated) {
      const allowedUserTypes = to.meta.userType;

      // Check if the user is authorized for the route
      if (Array.isArray(allowedUserTypes)) {
        if (!allowedUserTypes.includes(userType)) {
          return next({ path: "/404" }); // Redirect to 404 if unauthorized
        }
      } else if (allowedUserTypes !== userType) {
        return next({ path: "/404" }); // Redirect to 404 if unauthorized
      }
    }
    next(); // Allow access to the route
  } catch (error) {
    const isAuthenticated = to.matched.some(
      (record) => record.meta.requiresAuth
    );

    if (isAuthenticated) {
      // Determine the login path based on the `meta.userType` of the route
      let loginPath = "/agency-login"; // Default login path
      if (to.meta.userType === "Employee") {
        loginPath = "/employer-login"; // Redirect to employer login
      }

      next({
        path: loginPath,
        query: { redirect: to.fullPath }, // Pass the redirect path
      });
    } else {
      next(); // Proceed to the public route
    }
  }
});

//07122024
// router.beforeResolve(async (to, from, next) => {
//   console.log(to);

//   try {
//     const user = await Auth.currentAuthenticatedUser();
//     const userType = user.attributes["custom:userType"];
//     const isAuthenticated = to.matched.some(
//       (record) => record.meta.requiresAuth
//     );

//     if (isAuthenticated) {
//       const allowedUserTypes = to.meta.userType;

//       // Check if the user is authorized for the route
//       if (Array.isArray(allowedUserTypes)) {
//         if (!allowedUserTypes.includes(userType)) {
//           return next({ path: "/404" }); // Redirect to 404 if unauthorized
//         }
//       } else if (allowedUserTypes !== userType) {
//         return next({ path: "/404" }); // Redirect to 404 if unauthorized
//       }
//     }
//     next(); // Allow access to the route
//   } catch (error) {
//     const isAuthenticated = to.matched.some(
//       (record) => record.meta.requiresAuth
//     );

//     if (isAuthenticated) {
//       // Redirect to the appropriate login page based on route userType
//       const loginPath =
//         to.meta.userType && to.meta.userType.includes("Employer")
//           ? "/employer-login"
//           : "/agency-login";
//       next({
//         path: loginPath,
//         query: { redirect: to.fullPath },
//       });
//     } else {
//       next(); // Proceed to the public route
//     }
//   }
// });
// router.beforeResolve((to, from, next) => {
//   console.log("hi", to);

//   Auth.currentAuthenticatedUser()
//     .then((user) => {
//       const userType = user.attributes["custom:userType"];

//       if (to.matched.some((record) => record.meta.requiresAuth)) {
//         if (userType === "Agency" && to.path.startsWith("/agency-home")) {
//           next();
//         } else if (
//           userType === "Employee" &&
//           to.path.startsWith("/employer-home")
//         ) {
//           next();
//         } else {
//           // Redirect to the appropriate home page if trying to access the wrong one
//           if (userType === "Agency") {
//             next({ path: "/agency-home" });
//           } else if (userType === "Employee") {
//             next({ path: "/employer-home" });
//           } else {
//             next({ path: "/agency-login" });
//           }
//         }
//       } else {
//         next(); // If the route doesn't require authentication, allow access
//       }
//     })
//     .catch(() => {
//       if (to.matched.some((record) => record.meta.requiresAuth)) {
//         next({
//           path: "/agency-login",
//           query: { redirect: to.fullPath },
//         });
//       } else {
//         next();
//       }
//     });
// });

router.beforeEach((To, From, next) => {
  NProgress.configure({ showSpinner: false });
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
